import axios from 'axios';

const api = axios.create({

    baseURL: process.env.VUE_APP_URL_API

})

// api.defaults.headers.common['Authorization'] = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Vzc28iOiJ3ZWJDbGllbnRlIiwiaWRVc2VyIjoxLCJub21lIjoiTWFyY29zIFJvYmVydG8iLCJlbWFpbCI6ImtlcGFzN0BnbWFpbC5jb20iLCJpYXQiOjE2Mjk4MjUwMjYsImV4cCI6MTYzMjQxNzAyNn0.vFPcvdC2JJEaDU_d3R_O9PQvtckBdBgzuSQv6BQdmtQ'


const success = res => res
const error = err => {
    if (401 === err.response.status) {
        console.log('Expulsooooo')
        window.location = '/login'
    } else {
        return Promise.reject(err)
    }
}

api.interceptors.response.use(success, error)

export default api;