<template>

  <div id="login">
    <div class="header_candidatos">
      <Header />
    </div>
<!-- 
    <section id="sidebar">
      <div class="sidebar-brand ">

        
      </div> -->
      <!-- <div class="text-sidebar text-center mt-5">
        <h2> Você utiliza soluções em TI a favor do seu negócio?</h2>
      </div> -->

    <!-- </section> -->
    <div class="box-main">
      <div class="row">
        <div class="container-fluid">
          <div class="pro-table">

            <div class="loginArea" v-if="!this.acessandoForaDoComputador && !this.esqueciASenha">
              <Loading />

              <br>

              <b-img center src="../../../assets/logo/contratei.png" alt="technoPlus"></b-img>

              <br>

              <div class="text-center">

                Olá! Realize o login para continuar

              </div>

              <b-form @submit.prevent="handleLogin" class="centralizado">

                <div class="form-group ">
                  <b-form-input type="email" class="form-control" placeholder="Seu e-mail" v-model="email">
                  </b-form-input>
                </div>

                <div class="form-group">
                  <b-form-input id="senha" type="password" class="form-control" placeholder="Sua senha" v-model="password">
                  </b-form-input>
                </div>

                <br>

                <b-button type="submit" class="btn-vinho" @click="signin">Acessar </b-button>
                
                <br>
                <br>

                <a style="color: #F5445E; cursor: pointer" @click="toEsqueciASenha">Esqueci a senha</a>

              </b-form>
              {{ nome }}

            </div>

            <div v-if="this.acessandoForaDoComputador">

              <br>
              
              <b-img center src="../../../assets/logo/technoPlus.png" alt="technoPlus"></b-img>
              
              <br>

              <span class="aviso-resolucao">Aparentemente você está acessando o sistema por meio de um dispositivo móvel. Para desbloquear a tela e efetuar o login acesse o sistema por meio de um computador.</span>

            </div>

            <div v-if="this.esqueciASenha && !this.acessandoForaDoComputador" class="centralizado">

              <br>
              
              <b-img center src="../../../assets/logo/technoPlus.png" alt="technoPlus"></b-img>
              
              <br>

              <div class="text-center">

                Esqueceu a senha?

              </div>

              <b-form-input placeholder="Digite o seu e-mail" v-model="emailRecuperacaoSenha"></b-form-input>

              <br>

              <b-button variant="none" class="button-enviar-email" @click="enviarEmailRecuperacao">Enviar e-mail</b-button>

              <br>
              <br>

              <a class="lembrei-minha-senha" @click="toLogin">Lembrei minha senha</a>

            </div>


          </div>

          <b-modal id="selecionar-empresa" ref="moda-selecionar-empresa" size="sm" hide-footer hide-header>
            <div class="loginArea2">

              <div class="text-center">
                Onde deseja acessar?
              </div>

              <br>

              <b-form @submit.prevent="empresa" class="centralizado">

                <div class="form-group ">
                  <b-form-select 
                  text="Selecione" 
                  v-model="empresa"
                  :options="empresas"
                  style="border-color: #9C9C9C"
                  ></b-form-select>
                </div>

                <br>

                <b-button type="submit" class="btn-vinho " @click="acessar">Acessar </b-button>

              </b-form>

              <!-- <b-form>
                <div class="d-block text-center">
                  <b-dropdown text="Selecione" variant="primary" class="m-2">
                    <b-dropdown-item link to="/lero">Salus Enfermagem</b-dropdown-item>
                    <b-dropdown-item link to="/candidatos">Assistenza</b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-form> -->

            </div>
          </b-modal>

          <br>

          <div class="centralizado" style="color: #7A7A7A">
            <span>
              <a href="https://technoplusltda.zohodesk.com/portal/pt-br/kb/contratei" style="color: #F5445E;">Ajuda</a> | Technoplus Soluções em TI
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../../services/firebaseConnection";
import api from "../../../services/api";

export default {
  name: "Login",
  data() {
    return {
      headerBgVariant: "danger",
      headerTextVariant: "light",

      nome: "",
      email: "",
      password: "",
      login: true,
      empresa: null,

      empresas: [],

      acessandoForaDoComputador: false,

      esqueciASenha: false,
      emailRecuperacaoSenha: null

    };
  },

  created() {

    if (screen.width < 1024 || screen.height < 768) this.acessandoForaDoComputador = true

  },

  methods: {

    signin() {

      const dados = {
        email: this.email,
        senha: this.password
      }

      api.post('web-cliente/signin/empresas', dados)
      .then(res => {
        // console.log(res.data)
        this.$store.commit("auth/setUser", res.data);
        this.login = !this.login;

        this.empresas = res.data.empresas

        if (this.empresas.length > 1) this.$refs['moda-selecionar-empresa'].show()

        else {

          this.empresa = this.empresas[0].value
          this.acessar()

        }

      })
      .catch((err) => {
          this.msg("ATENÇÃO", err.response.data, "danger");
      });

    },

    acessar() {

      const dados = {
        email: this.email,
        senha: this.password,
        idEmpresa: this.empresa
      }

      api.post('web-cliente/signin', dados)
      .then(res => {

        localStorage.setItem("emailUsuario", JSON.stringify(dados.email));
        this.$store.commit("setEmpresaSelecionada", res.data);
        this.$router.push('/candidatos');

      })
      .catch((err) => {
          this.msg("ATENÇÃO", err.response.data, "danger");
      });

    },

    async handleLogin() {
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password);

      //Buscar o nome do usuario logado
      const userName = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get();

      console.log(user);

      const usuarioLogado = {
        uid: user.uid,
        nome: userName.data().nome,
      };

      localStorage.setItem("usuario", JSON.stringify(usuarioLogado));
      this.$router.push("/");
    },

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    toEsqueciASenha() {
      this.esqueciASenha = true
    },

    toLogin() {
      this.esqueciASenha = false
    },

    enviarEmailRecuperacao() {

      api.post(`web-cliente/recuperar-senha/${this.emailRecuperacaoSenha}`)
      .then(() => {

        this.esqueciASenha = false
        this.msg("Mensagem enviada", 'Verifique seu e-mail.', "success")

      })
      .catch((err) => {
          this.msg("ATENÇÃO", err.response.data, "danger");
      });

    }
    
  },
};
</script>

<style lang="scss" scoped>
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #F5445E;
  border-color: #F5445E;
}

@media screen and (max-width: 480) {
  .container[data-v-15717af5] {
    max-width: 1330px;
    margin-top: -47%;
    margin: 0 auto;
    padding-left: 2px;
    padding-right: 0;
    margin-top: -14%;
  }
}

.container {
  max-width: 1330px;
  margin: 0 auto;
  padding-left: 247px;
  padding-right: 15px;
  margin-top: -14%;
}

// @media screen and (max-width: 768px) {
//   .row {
//     display: flex;
//     flex-wrap: wrap;
//     margin-right: -3px;
//     margin-left: -24px;
//     width: 100%;
//   }
// }

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #D83C56;
  border: none;
  box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
}

.box {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 150px;
}

.box-main {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  position: fixed;
  right: 0;
  /* top: 0; */
  color: #fff;
  background-image: linear-gradient(#F5445E 50%, #EE6C3E), linear-gradient(#EEEEEE 0, #EEEEEE 100%);
  background-size: 100% 50%;
  background-position: top, bottom;
  background-repeat: no-repeat;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .box-main {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    color: #fff;
    height: 100%;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .box-main {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    color: #fff;
    height: 100%;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.clear {
  clear: both;
}

.btn-vinho {
  background-color: #F5445E;
  border: none;
  border-radius: 70px;
  width: 55%;
}

.btn-vinho:hover {
  background-color: #D83C56;
  border-color: #D83C56;
}

.active {
  width: 40% !important;
  z-index: 20 !important;
}

.menu-hide {
  display: inline-block !important;
}

/*sidebar css*/

section#sidebar {
  width: 20%;
  position: fixed;
  left: 0;
  color: #fff;
  height: 100%;
  background-image: linear-gradient(
      rgba(250, 218, 35, 0.8),
      rgba(248, 118, 12, 0.8)
    ),
    url(../../../assets/tech.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 1s ease;
}

@media screen and (max-width: 768px) {
  section#sidebar {
    width: 100%;
  }
}

.sidebar-menu ul li {
  transition: all 0.5s ease;
  list-style: none; /*<--TIRAR OS PONTINHO UL,LI*/
}

.pro-table {
  padding: 15px;
  background-color: #fff;
  border-radius: 7px;
  // box-shadow: 10px 10px 20px #bbb;
}

.pro-table2 {
  padding: 15px;
  background-color: #fff;
  border-radius: 7px;
  // box-shadow: 10px 10px 20px #bbb;
}

.loginArea {
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 350px;
  height: 450px;
  padding: 20px;
}

.loginArea2 {
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;
}

section#main-content {
  width: 84%;
}

button {
  width: 100%;
}

.form-control,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  border-color: #9C9C9C;
}

.Login-titulo {
  font-size: 24px;
  color: rgb(15, 15, 15);
}

.Login-titulo2 {
  font-size: 24px;
  color: rgb(15, 15, 15);
}

.text-sidebar {
  padding: 4px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transform: skewY(-5deg);
  text-shadow: 1px 1px 0 #ccc, 2px 2px 0 #ccc, 3px 3px 0 #ccc, 4px 4px 0 #ccc,
    5px 5px 0 #ccc, 7px 7px 0 rgba(0, 0, 0, 0.2);
  animation: floating 5s ease-in-out infinite;
}

.sidebar-brand,
.img-top {
  margin-top: 20%;
}

.text-center {
  text-align: center !important;
  font-size: 22px;
  font-weight: 500;
  color: #000;
  margin-bottom: 14px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #000;
}

.centralizado {
  text-align: center;
}

.aviso-resolucao {
  color: black;
}

.button-enviar-email {
  background-color: #F5445E;
  color: white;
  border-radius: 20px;
}

.button-enviar-email:hover {
  color: white;
}

.lembrei-minha-senha {
  color: #F5445E;
  cursor: pointer;
}

</style>
