import api from "../../../services/api";
import axios from "axios";
export default {
  name: "AdesaoCad",
  props: ["emp"],

  data() {
    return {
      adesao: [],
      titulo: "Nova Adesão",

      logo: "salus.png",

      ativProf: [],

      form: {
        nome: "",
        cpf: "",
        email: "",
        confEmail: "",
        celular: "",
        celAdic: "",
        fixo: "",
        cep: "",
        cidade: "",
        uf: "",
        bairro: "",
        endereco: "",
        atividadeProf: null,
        regProf: "",
        regNum: "",
        regDataVencimento: null
      },

      // form: {
      //   nome: " marcos rober",
      //   cpf: "329.207.778-82",
      //   email: "kepas7@gmail.com",
      //   confEmail: "kepas7@gmail.com",
      //   celular: "11111",
      //   celAdic: "",
      //   fixo: "",
      //   cep: "03570-370",
      //   cidade: "São Paulo",
      //   uf: "as",
      //   bairro: "asas",
      //   endereco: "asas",
      //   atividadeProf: 1,
      //   regProf: "1212",
      //   regNum: "12",
      //   regDataVencimento: null
      // },
     
      show: true,
      botaoVisivel: true,

      dadosEmpresa: null,
      bgColor: "#a1a1a1",

      naoRecebiCodigo: false,
      codVerificacao: ["", "", "", "", "", ""],

      idSolicitacao: '',

      msgValidacao: '',

      enviandoEmail: false,
      confirmandoEmail: false,

      keyValidacao: null,

      tituloModalVerificacao: 'Validação de e-mail'

    };
  },

  created() {

    // alert(this.$route.query.emp)
    const empresa = this.$route.query.emp
    const keyValidacao = this.$route.query.keyValidacao

    api
      .get(`adesao/empresa/${empresa}/null`)
      .then((res) => {
        // console.log(res.data);
        this.dadosEmpresa = res.data;
        this.bgColor = res.data.cor;
        this.logo = res.data.logoPath;
        this.ativProf = res.data.funcoes;

        if (!this.dadosEmpresa) this.$router.push("/adesao/erro");

        if (keyValidacao) {
          
          this.keyValidacao = keyValidacao
          this.tituloModalVerificacao = 'Bem vindo de volta!'
          this.getDadosSolicitacaoParaConfirmarEmail(keyValidacao)
        
        }

      })
      .catch(() => {
        if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
        //this.msg("ERRO AO BUSCAR", err.response.data, "danger")
      });

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 10000,
        appendToast: true
      });
    },
 

    insertAdesao(evt) {
      evt.preventDefault();

      this.botaoVisivel = false

      this.form.idEmpresa = this.dadosEmpresa.id
      this.form.nomeEmpresa = this.dadosEmpresa.nomeEmpresa
      this.form.diasReprovado = this.dadosEmpresa.diasReprovado
      this.form.codUrl = this.dadosEmpresa.codUrl
      
      api
        .post("adesao", { ...this.form, indVerificacaoEmail: this.dadosEmpresa.indVerificacaoEmail })
        .then((res) => {

          //console.log(res.data);
          if (res.data == 'ok') {

            this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
            localStorage.setItem('idMsg', 10)

          } else 
          if (res.data == 'ok - entrevista') {

            this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
            localStorage.setItem('idMsg', 15)

          } else 
          if (res.data == 'ok?' || res.data == 'ok? - entrevista') {

            this.$refs['my-modal'].show()
            return

          } else
          if (res.data.precisaVerificar) {

            this.idSolicitacao = res.data.idSolicitacao
            this.msgValidacao = res.data.msgValidacao
            this.$refs['modal-verificacao-email'].show()
            return

          } else if (res.data.includes("http://") || res.data.includes("https://")) {

            this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
            this.form = '';
            localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
            window.location.replace(res.data)
            return

          }

          this.form = '';
          localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
          this.$router.push('/msg');

        })
        .catch((err) => {
          this.msg("ATENÇÃO!", err.response.data, "danger")
          this.botaoVisivel = true
        });

    },


    buscarCep() {
      // console.log("cep", this.form.cep);

      this.form.endereco = null
      this.form.bairro = null
      this.form.cidade = null
      this.form.uf = null

      if (this.form.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.form.cep}/json`)
          .then((res) => {
            console.log(res.data);

            const dadosCep = res.data;
            this.form.endereco = dadosCep.logradouro;
            this.form.bairro = dadosCep.bairro;
            this.form.cidade = dadosCep.localidade;
            this.form.uf = dadosCep.uf;
          })
          .catch((err) => this.msg("Erro: cep não encontrado.", err, "danger"));
      }

    },

    getEntidade() {
      
      api
      .get('adesao/empresa-entidade/' + this.form.atividadeProf)
      .then((res) => {
        
        this.form.regProf = res.data
      })
      .catch(() => this.msg("ATENÇÃO", 'Erro ao buscar entidade', 'danger'));

    },


    hideModal() {
      this.$refs['my-modal'].hide()
    },
    confirmaAdesao(acao) {
      
      this.$refs['my-modal'].hide()

      if (acao == 'sim') {

        api.put(`adesao/confirma-ok/${this.dadosEmpresa.id}/${this.form.cpf}`)
        .then(() => {
          this.form = '';
          localStorage.setItem('idMsg', 10)
          localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
          this.$router.push('/msg');
        })
        .catch(() => this.msg("ATENÇÃO", 'Erro...', 'danger'));

      } else {
        
        api.delete(`adesao/${this.dadosEmpresa.id}/${this.form.cpf}`)
        .then(() => {
          this.msg('ADESÃO', 'CADASTRADO NÃO CONCLUÍDO.', 'warning');
        })
        .catch(() => this.msg("ATENÇÃO", 'Erro...', 'danger'));

      }

    },

    handleInput(index) {

      // Garante que apenas números sejam aceitos.
      this.codVerificacao[index] = this.codVerificacao[index].replace(/\D/g, "")

      if (this.codVerificacao[index] && index < this.codVerificacao.length - 1) 
      this.$refs.otpInputs[index + 1].$el.focus()

      if (this.codVerificacao.every((digit) => digit !== "")) 
      this.$emit("otp-completo", this.codVerificacao.join(""))
    
    },

    handleBackspace(index) {

      if (!this.codVerificacao[index] && index > 0) 
      this.$refs.otpInputs[index - 1].$el.focus()

    },

    handleKeydown(event) {
      
      if (event.ctrlKey && event.key === "v") return
      if (event.metaKey && event.key === "v") return
    
      // Impede entrada de caracteres não numéricos (exceto backspace e tab).
      if (!/[0-9]/.test(event.key) && !["Backspace", "Tab"].includes(event.key)) event.preventDefault()

    },

    handlePaste(event, index) {
      
      event.preventDefault()
    
      let pasteData = (event.clipboardData || window.clipboardData).getData("text")
      pasteData = pasteData.replace(/\D/g, "")
    
      if (!pasteData) return
    
      // Distribui os números colados nos campos de entrada.
      for (let i = 0; i < pasteData.length; i++) {

        if (index + i < this.codVerificacao.length) 
        this.$set(this.codVerificacao, index + i, pasteData[i])
      
      }
    
      let nextIndex = index + pasteData.length

      if (nextIndex < this.codVerificacao.length) this.$refs.otpInputs[nextIndex].$el.focus();
    
      // Se todos os campos estiverem preenchidos, emite o evento.
      if (this.codVerificacao.every((digit) => digit !== "")) this.$emit("otp-completo", this.codVerificacao.join(""))

    },    

    confirmarCodigoVerificacao() {

      this.confirmandoEmail = true

      api.post('adesao/confirma-codigo-email', { 
        
        idSolicitacao: this.idSolicitacao, 
        codVerificacao: this.codVerificacao.join(''),
        codUrl: this.dadosEmpresa.codUrl,
        nomeEmpresa: this.dadosEmpresa.nomeEmpresa,
        msgValidacao: this.msgValidacao,

      })
      .then((res) => {

        this.confirmandoEmail = false

        if (res.data == 'ok') {

          this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
          localStorage.setItem('idMsg', 10)

        } else 
        if (res.data == 'ok - entrevista') {

          this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
          localStorage.setItem('idMsg', 15)

        } else 
        if (res.data == 'ok?' || res.data == 'ok? - entrevista') {

          this.$refs['my-modal'].show()
          return

        } else if (res.data.includes("http://") || res.data.includes("https://")) {

          this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
          this.form = '';
          localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
          window.location.replace(res.data)
          return

        }

        this.form = '';
        localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
        this.$router.push('/msg');

      }).catch((error) => {

        this.confirmandoEmail = false
        this.msg('ERRO', error.response.data, 'danger')

      })

    },

    reenviarEmail() {

      this.enviandoEmail = true

      api.put('adesao/reenvia-confirmacao-email', {

        idSolicitacao: this.idSolicitacao,
        email: this.form.email,
        emailConfirma: this.form.confEmail,
        nomeEmpresa: this.dadosEmpresa.nomeEmpresa,
        codUrl: this.dadosEmpresa.codUrl

      })
      .then(() => {

        this.codVerificacao = ["", "", "", "", "", ""]
        this.enviandoEmail = false
        this.naoRecebiCodigo = false

      }).catch((error) => {

        this.enviandoEmail = false
        this.msg('ERRO', error.response.data, 'danger')

      })

    },

    getDadosSolicitacaoParaConfirmarEmail(keyValidacao) {

      api.get(`adesao/solicitacao-confirmacao/${keyValidacao}`)
      .then((res) => {

        if (res.data.status != '24' && res.data.status != 25) this.$router.push("/adesao/erro");
        else {
      
          this.idSolicitacao = res.data.idSolicitacao
          this.msgValidacao = res.data.msgValidacao
          this.$refs['modal-verificacao-email'].show()

        }

      }).catch((error) => {

        this.msg('ERRO', error.response.data, 'danger')

      })

    },

    naoRecebiCodigoToggle() {

      this.form.email = ''
      this.form.confEmail = ''
      this.naoRecebiCodigo = true

    }

  },
  

  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
      };
    },
  },
};